<template>
 
      <v-container class="pt-5">
        <v-row>
          <v-col md="3" >
            <v-text-field
              :hint="$t('requird')"
              dense
              outlined 
              
              color="#fff"             
              :label="$t('student name')"
              v-model="newStudent.first_name"
            ></v-text-field>
          </v-col>
          <v-col md="3">
            <v-text-field
              :hint="$t('requird')"
              dense
              outlined
              color="#757575"
              :label="$t('last name')"
              v-model="newStudent.last_name"
            ></v-text-field>
          </v-col>
          <v-col md="3">
            <v-text-field
              :hint="$t('requird')"
              dense
              outlined
              color="#757575"
              :label="$t('father name')"
              v-model="newStudent.father_name"
            ></v-text-field>
          </v-col>
          <v-col md="3">
            <v-text-field
              :hint="$t('requird')"
              dense
              outlined
              color="#757575"
              :label="$t('grand father name')"
              v-model="newStudent.grand_father_name"
            ></v-text-field>
          </v-col>
          <v-col md="3">
            <v-text-field
              :hint="$t('requird')"
              dense
              outlined
              color="#757575"
              :label="$t('mother name')"
              v-model="newStudent.mother_name"
            ></v-text-field>
          </v-col>
          <v-col md="3">
            <input
              style="
                width: 254.22px;
                height: 40p;
                border: 1px solid #999;
                border-radius: 4px;
                padding: 9px;
                text-align: center;
              "
              type="date"
              v-model="newStudent.birthday"
            />
          </v-col>
          <v-col md="3">
            <v-text-field
              :hint="$t('requird')"
              outlined
              dense
              color="#757575"
              v-model="newStudent.birth_location"
              :label="$t('birthday place')"
            ></v-text-field>
          </v-col>
          <v-col md="3">
            <v-text-field
              outlined
              dense
              color="#757575"
              v-model="newStudent.father_job"
              :label="$t('father career')"
            ></v-text-field>
          </v-col>
          <v-col md="3">
            <v-text-field
              outlined
              dense
              color="#757575"
              v-model="newStudent.mobile_number"
              :label="$t('phone number')"
              type="number"
            ></v-text-field>
          </v-col>
          <v-col md="3">
            <v-text-field
              outlined
              dense
              color="#757575"
              v-model="newStudent.phone_number"
              :label="$t('land phone number')"
              type="number"
            ></v-text-field>
          </v-col>
          <v-col md="3">
            <v-text-field
              outlined
              dense
              color="#757575"
              v-model="newStudent.foreign_language"
              :label="$t('foreign language')"
            ></v-text-field>
          </v-col>
          <v-col md="3">
            <v-text-field
              outlined
              dense
              color="#757575"
              v-model="newStudent.nationality"
              :label="$t('nationalty')"
            ></v-text-field>
          </v-col>
          <v-col md="3">
            <v-text-field
              outlined
              dense
              color="#757575"
              v-model="newStudent.student_email"
              :label="$t('student email')"
            ></v-text-field>
          </v-col>
          <v-col md="3">
            <v-text-field
              outlined
              dense
              color="#757575"
              v-model="newStudent.parent_email"
              :label="$t('parent email')"
            ></v-text-field>
          </v-col>
          <v-col md="3">
            <v-text-field
              outlined
              dense
              color="#757575"
              v-model="newStudent.parent_passport_number"
              :label="$t('parent passport number')"
            ></v-text-field>
          </v-col>
          <v-col md="3">
            <v-text-field
              outlined
              dense
              color="#757575"
              v-model="newStudent.student_passport_number"
              :label="$t('student passport number')"
            ></v-text-field>
          </v-col>
          <v-col md="3">
            <v-text-field
              outlined
              dense
              color="#757575"
              v-model="newStudent.address"
              :label="$t('address')"
            ></v-text-field>
          </v-col>
          <v-col md="3">
            <v-text-field
              outlined
              dense
              color="#757575"
              v-model="newStudent.student_contact"
              :label="$t('student contact')"
            ></v-text-field>
          </v-col>
          <v-col md="3">
            <v-text-field
              outlined
              dense
              color="#757575"
              v-model="newStudent.birth_registration_id"
              :label="$t('Registration number')"
            ></v-text-field>
          </v-col>

          <v-col md="3">
            <input
              style="
                width: 254.22px;
                height: 40p;
                border: 1px solid #999;
                border-radius: 4px;
                padding: 9px;
                text-align: center;
              "
              type="date"
              v-model="newStudent.join_date"
            />
          </v-col>
          <v-col md="3">
            <v-text-field
              :hint="$t('requird')"
              :label="$t('public record number')"
              v-model="newStudent.public_record_number"
              outlined
              dense
              color="#757575"
              type="number"
            ></v-text-field>
          </v-col>
          <v-col md="3" class="py-0">
            <v-radio-group
              v-model="newStudent.gender"
              row
              :label="$t('gender')"
            >
              <v-radio
                :label="$t('male')"
                value="male"
                color="#757575"
              ></v-radio>
              <v-radio
                :label="$t('female')"
                value="female"
                color="#757575"
              ></v-radio>
            </v-radio-group>
          </v-col>
          <v-col md="3" class="py-0">
            <v-radio-group
              v-model="newStudent.type"
              row
              :label="$t('type')"
              @change="
                newStudent.number_transfer_document = null;
                newStudent.transfer_document_photo = null;
              "
            >
              <v-radio :label="$t('new')" value="new" color="#757575"></v-radio>
              <v-radio
                :label="$t('Transported')"
                value="transported"
                color="#757575"
              ></v-radio>
            </v-radio-group>
          </v-col>
          
          <v-col md="3">
            <v-fade-transition mode="out-in">
              <v-text-field
                v-if="newStudent.type == 'transported'"
                dense
                outlined
                color="#757575"
                label="رقم الانتقال"
                type="number"
                v-model="newStudent.number_transfer_document"
              ></v-text-field>
            </v-fade-transition>
          </v-col>
          
          <v-col md="6">
            <v-text-field
              outlined
              dense
              color="#757575"
              v-model="newStudent.buffet_note"
              :label="$t('buffet_note')"
            ></v-text-field>
          </v-col>
          <v-col md="3">
            <v-fade-transition mode="out-in">
              <input 
              style="
                width: 254.22px;
                height: 40p;
                border: 1px solid #999;
                border-radius: 4px;
                padding: 9px;
                text-align: center;
              "
                v-if="newStudent.type == 'transported'"
                color="#757575"
                type="date"
                v-model="newStudent.transfer_document_date"
              />
            </v-fade-transition>
          </v-col>

          <v-col md="2" class="d-flex justify-end pl-5">
            <v-btn
              color="green"
              class="white--text"
              depressed
              @click="submitStudent"
              :loading="submitBtnLoading"
              >{{ $t("storage") }}</v-btn
            >
          </v-col>
          <v-col md="12">
            <v-row>
              <v-col md="4">
                <app-image-input-file
                  :resetImage="resetImageInput"
                  :createFeature="true"
                  :imgHeight="365"
                  :imgWidth="350"
                  :placeHolder="$t('student photo')"
                  @fileValue="setStudentPhoto($event, 'student_photo')"
                  @fileRemoved="deleteStudentPhoto('student_photo')"
                ></app-image-input-file>
              </v-col>
              <v-col md="4">
                <v-row>
                  <v-col md="6">
                    <app-image-input-file
                      :resetImage="resetImageInput"
                      :createFeature="true"
                      :imgHeight="170"
                      :imgWidth="150"
                      :placeHolder="$t('Father Residence/ID Photo')"
                      @fileValue="setStudentPhoto($event, 'family_book_1')"
                      @fileRemoved="deleteStudentPhoto('family_book_1')"
                    ></app-image-input-file>
                  </v-col>
                  <v-col md="6">
                    <app-image-input-file
                      :resetImage="resetImageInput"
                      :createFeature="true"
                      :imgHeight="170"
                      :imgWidth="150"
                      :placeHolder="$t('Father Passport Photo')"
                      @fileValue="setStudentPhoto($event, 'family_book_2')"
                      @fileRemoved="deleteStudentPhoto('family_book_2')"
                    ></app-image-input-file>
                  </v-col>
                  <v-col md="6">
                    <app-image-input-file
                      :resetImage="resetImageInput"
                      :createFeature="true"
                      :imgHeight="170"
                      :imgWidth="150"
                      :placeHolder="$t('Student Residence/ID Photo')"
                      @fileValue="setStudentPhoto($event, 'family_book_3')"
                      @fileRemoved="deleteStudentPhoto('family_book_3')"
                    ></app-image-input-file>
                  </v-col>
                  <v-col md="6">
                    <app-image-input-file
                      :resetImage="resetImageInput"
                      :createFeature="true"
                      :imgHeight="170"
                      :imgWidth="150"
                      :placeHolder="$t('Student Passport Photo')"
                      @fileValue="setStudentPhoto($event, 'family_book_4')"
                      @fileRemoved="deleteStudentPhoto('family_book_4')"
                    ></app-image-input-file>
                  </v-col>
                </v-row>
              </v-col>
              <v-col md="4">
                <v-row>
                  
                  <v-col md="6">
                    <app-image-input-file
                      :resetImage="resetImageInput"
                      :createFeature="true"
                      :imgHeight="170"
                      :imgWidth="150"
                      :placeHolder="$t('Vaccine card')"
                      @fileValue="setStudentPhoto($event, 'vaccine_card')"
                      @fileRemoved="deleteStudentPhoto('vaccine_card')"
                    ></app-image-input-file>
                  </v-col>
                  <v-col md="6">
                    <app-image-input-file
                      :resetImage="resetImageInput"
                      :createFeature="true"
                      :imgHeight="170"
                      :imgWidth="150"
                      :placeHolder="$t('Academic sequence')"
                      @fileValue="setStudentPhoto($event, 'study_sequence')"
                      @fileRemoved="deleteStudentPhoto('study_sequence')"
                    ></app-image-input-file>
                  </v-col>
                  <v-col md="6">
                    <app-image-input-file
                      :resetImage="resetImageInput"
                      :createFeature="true"
                      :imgHeight="170"
                      :imgWidth="150"
                      :placeHolder="$t('medical report')"
                      @fileValue="setStudentPhoto($event, 'medical_report')"
                      @fileRemoved="deleteStudentPhoto('medical_report')"
                    ></app-image-input-file>
                  </v-col>
                </v-row>
              </v-col>
              <v-col md="4">
                <v-fade-transition mode="out-in">
                  <app-image-input-file
                    :resetImage="resetImageInput"
                    :createFeature="true"
                    v-if="newStudent.type == 'transported'"
                    :imgHeight="170"
                    :imgWidth="150"
                    :placeHolder="$t('Transfer document')"
                    @fileValue="
                      setStudentPhoto($event, 'transfer_document_photo')
                    "
                    @fileRemoved="deleteStudentPhoto('transfer_document_photo')"
                  ></app-image-input-file>
                </v-fade-transition>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>

</template>

<script>
import imageInputFile from "../../../../components/base/imageInputFile.vue";
const studentState = () => {
  return {
    gender: "male",
    type: "new",
    first_name: "",
    last_name: "",
    father_name: "",
    grand_father_name: "",
    mother_name: "",
    father_job: "",
    birthday: "2015-01-01",
    birth_location: "",
    join_date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    birth_registration_id: "",
    foreign_language: "مشتركة",

    nationality: "العراق",
    student_email: "",
    parent_email: "",
    parent_passport_number: "",
    student_passport_number: "",
    address: "",
    student_contact: "",
    phone_number: null,
    mobile_number: null,
    student_photo: null,
    family_book_1: null,
    family_book_2: null,
    family_book_3: null,
    family_book_4: null,
    father_photo_id: null,
    vaccine_card: null,
    study_sequence: null,
    medical_report: null,
    transfer_document_photo: null,
    number_transfer_document: null,
    public_record_number: null,
    buffet_note: null,
    ordered_meals: null,
    transfer_document_date: null,
  };
};
export default {
  components: {
    appImageInputFile: imageInputFile,
  },
  data() {
    return {
      resetImageInput: false,
      birthdayDialog: false,
      joinDateDialog: false,
      submitBtnLoading: false,
      newStudent: studentState(),
    };
  },
  methods: {
    setStudentPhoto(file, photoType) {
      this.newStudent[photoType] = file;
    },
    deleteStudentPhoto(photoType) {
      this.newStudent[photoType] = null;
    },
    submitStudent() {
      this.submitBtnLoading = true;
      this.$store
        .dispatch("createStudent/postNewStudent", this.newStudent)
        .then((_) => {
          Object.assign(this.newStudent, studentState());
          this.resetImageInput = true;
          setTimeout(() => {
            this.resetImageInput = false;
          }, 100);
          this.$Notifications(
            "تم تسجيل الطالب",
            { timeout: 2000, rtl: true },
            "success"
          );
        })
        .finally((_) => {
          this.submitBtnLoading = false;
          this.$router.go(-1);
        });
    },
  },
};
</script>

<style></style>
